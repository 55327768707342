import {
    Grid,
    Button,
    TextField,
    Typography,
    Box,
    InputLabel,
    Select,
    MenuItem,
    TableContainer,
    Table,
    IconButton,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Collapse,
    Tooltip,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    CardContent,
    FormControl,
    Autocomplete
} from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
// import BG from '../../../assets/BW TECNOLOGIA.png';
import BG from '../../../assets/images/Backgrounds/loginBG.jpg';
import LogoJGF from '../../../assets/jgf-logo.png';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import SkeletonSimulator from 'ui-component/SkeletonSimulator';
import { useParams } from 'react-router';

const BudgetFormation = ({ open, setOpen, formData, getBudget }) => {
    const { id } = useParams();
    const api = useApi();
    const [formOpen, setFormOpen] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupValue, setSelectedGroupValue] = useState(null);
    const [selectedQuizValue, setSelectedQuizValue] = useState(null);

    const [allGroup, setAllGroup] = useState([]);
    const [allQuiz, setAllQuiz] = useState([]);
    const [respostasSelecionadas, setRespostasSelecionadas] = useState([]);
    const [perguntaAtual, setPerguntaAtual] = useState(0);
    const [mostrarErro, setMostrarErro] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState([]);
    const [currentAnswer, setCurrentAnswer] = useState([]);
    const [allBudgets, setAllBudgets] = useState([]);
    const [nome, setNome] = useState('');
    const [error, setError] = useState(false);

    const [note, setNote] = useState('');

    //image
    const [imageUrl, setImageUrl] = useState('');
    const [stepImage, setStepImage] = useState(0);

    const [preOrc, setPreOrc] = useState([]);
    const [saveModel, setSaveModel] = useState();

    const [selectedBudget, setSelectedBudget] = useState(null);

    const getAllAnswers = async () => {
        const promises = selectedQuiz?.map(async (item) => {
            const { data } = await api.getAnswerByCloseQuestion(item.id);
            return data;
        });
        const allAnswers = await Promise.all(promises);

        const newArrayAnswer = selectedQuiz?.map((item, index) => ({
            answers: allAnswers[index],
            question: item,
            imageUrl: item?.imageUrl,
            openQuestionRestriction: item.openQuestionRestriction
                ? {
                      description: item.openQuestionRestriction?.description,
                      min: item.openQuestionRestriction?.minimumValue,
                      max: item.openQuestionRestriction?.maximumValue
                  }
                : {}
        }));

        setAnswers(newArrayAnswer);
    };
    const [groupIsSelected, setGroupIsSelected] = useState(false);
    const [showAllGroups, setShowAllGroups] = useState(true);
    const getAllGroups = async () => {
        try {
            const res = await api.getAllGroups();

            setAllGroup(res.data);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getGroupRelated = async (groupID) => {
        try {
            const res = await api.getGroupRelatedQuiz(groupID);
            setSelectedGroup(res.data);
            setAllQuiz(res.data);
            setGroupIsSelected(true);
            setShowAllGroups(false);
        } catch (error) {
            console.log(error.message);
        }
    };

    async function getObservation() {
        try {
            const { data } = await api.getQuizNotesId(selectedQuizValue?.id);
            console.log('🚀 ~ getObservation ~ data:', data[0]?.observation);
            setNote(data[data.length - 1]?.observation);
        } catch (error) {
            console.log('🚀 ~ file: index.js ~ line 222 ~ getObservation ~ error', error);
        }
    }

    useEffect(() => {
        // getAllquiz();
        getAllGroups();
        // getAllBudgets();
        if (selectedQuiz) {
            getObservation();
            getAllAnswers(selectedQuiz);
        }
    }, [selectedQuiz]);

    const [openResponse, setOpenResponse] = useState(null);

    const handleRespostaSelecionada = (pergunta, resposta) => {
        setOpenResponse(resposta);

        let respostaObjeto;
        setCurrentQuestion(pergunta);
        setCurrentAnswer(resposta);
        if (pergunta.type === 'Aberta') {
            respostaObjeto = {
                question: pergunta,
                answer: {
                    value: resposta !== '' ? parseFloat(resposta) : null,
                    product: null
                },
                quizId: answers[perguntaAtual].quizId
            };
        } else {
            respostaObjeto = {
                question: pergunta,
                answer: resposta,
                quizId: answers[perguntaAtual].quizId
            };
        }

        setRespostasSelecionadas((prevRespostasSelecionadas) => ({
            ...prevRespostasSelecionadas,
            [pergunta.id]: respostaObjeto
        }));
    };

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleAvancar = async () => {
        setIsButtonDisabled(true);
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 500);

        const respostaAtual = respostasSelecionadas[answers[perguntaAtual].question.id];
        if (respostaAtual === undefined) {
            setMostrarErro(true);
            return;
        }

        if (
            openResponse < answers[perguntaAtual]?.openQuestionRestriction?.min ||
            openResponse > answers[perguntaAtual]?.openQuestionRestriction?.max
        ) {
            toast.warn(
                `Selecione um valor entre ${answers[perguntaAtual]?.openQuestionRestriction?.min} e ${answers[perguntaAtual]?.openQuestionRestriction?.max}`
            );
            return;
        }

        // ==================== LÓGICA PARA VERIFICAR A PRÓXIMA PERGUNTA ====================
        const resp = respostaAtual?.question?.id;
        const ListaPerguntas = answers;
        let proximoItem = null;
        let indexAtual = 0;
        for (let index = 0; index < ListaPerguntas.length; index++) {
            const item = ListaPerguntas[index];
            if (item?.question?.id === resp) {
                proximoItem = ListaPerguntas[index + 1];
                indexAtual = index + 1;
                break;
            }
        }

        //=================== Payload ===================================================
        const payload = Object.keys(respostasSelecionadas).map((key) => {
            const objt = respostasSelecionadas[key];
            const newObjt = {
                answer: { id: objt.answer.id },
                question: { id: objt.question.id }
            };

            return newObjt;
        });

        // ==================== LÓGICA PARA VERIFICAR SE POSSUI BODY FORMULA ====================
        // ==================== E PEGAR AS RESPOSTAS ====================
        if (proximoItem && proximoItem?.question?.haveBodyFormula) {
            try {
                const res = await api.checkformula(proximoItem.question.id, currentQuizMain.id, payload);

                if (res) {
                    const updatedAnswers = answers.map((item, index) => {
                        if (index === indexAtual) {
                            return {
                                ...item,
                                answers: res.data
                            };
                        }
                        return item;
                    });
                    setAnswers(updatedAnswers);
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        setStepImage((prev) => prev + 1);
        setPerguntaAtual((prevPergunta) => prevPergunta + 1);
        setRespostasSelecionadas((prevRespostasSelecionadas) => ({
            ...prevRespostasSelecionadas,
            [answers[perguntaAtual].question.id]: respostaAtual
        }));
        setMostrarErro(false);
    };

    const handleVoltar = () => {
        if (perguntaAtual === 0) {
            setSelectedQuiz(null);
            setNome('');
            handleQuizChange();
        } else {
            const prevQuestionIndex = perguntaAtual - 1;
            const prevQuestion = answers[prevQuestionIndex].question;
            setPerguntaAtual(prevQuestionIndex);
            setStepImage((prev) => prev - 1);
        }
    };

    // loading skeleton
    const [loading, setLoading] = useState(false);
    const [currentQuizMain, setCurrentQuizMain] = useState(null);

    const handleQuizChange = async (selectedOption) => {
        setCurrentQuizMain(selectedOption);

        setLoading(true);

        try {
            const { data } = await api.getQuizByIdV2(selectedOption.id);

            setSelectedQuiz(data);
            setNome(selectedOption?.product?.productName);
        } catch (error) {
            console.log('🚀 ~ error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setSelectedQuiz([]);
        setAllGroup([]);
        setAllQuiz([]);
        setSelectedGroup([]);
        setRespostasSelecionadas([]);
        setPerguntaAtual(0);
        setCurrentQuestion([]);
        setAnswers([]);
        setMostrarErro(false);
        // setAllBudgets([]);
        setCurrentAnswer([]);
        setStepImage(0);
        setPreOrc([]);
        setOpen(false);
        setGroupIsSelected(false);
        setNome('');
    };

    // Função para gerar o payload para orçamento
    const generatePayloadForBudget = (respostasSelecionadas) => {
        return Object.keys(respostasSelecionadas).map((key) => {
            const objt = respostasSelecionadas[key];
            return {
                answer: objt.answer,
                question: objt.question
            };
        });
    };

    // Função para substituir placeholders no texto
    const replacePlaceholders = (text, answersMap) => {
        return text?.replace(/\$(\d+)\$/g, (match, p1) => {
            const id = parseInt(p1, 10);
            const answer = answersMap[id];
            return answer ? answer : match;
        });
    };

    // Função para transformar respostasSelecionadas em um mapa para fácil acesso
    const createAnswersMap = (respostasSelecionadas) => {
        console.log('🚀 ~ createAnswersMap ~ respostasSelecionadas:', respostasSelecionadas);
        return respostasSelecionadas.reduce((map, obj) => {
            if (obj.question.type === 'Fechada') {
                map[obj.question.id] = obj.answer.publicDescription ? obj.answer.publicDescription : obj.answer.public_description;
            } else {
                map[obj.question.id] = obj.answer.value;
            }
            return map;
        }, {});
    };

    async function handleNote(id, note) {
        const payload = {
            note: note
        };

        try {
            await api.addNote(id, payload);
            getBudget();
        } catch (error) {
            console.log('🚀 ~ handleSubmit ~ error:', error);
        }
    }

    // Função handleSubmit
    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();

        // Gerar payload para orçamento
        const payloadForBudget = generatePayloadForBudget(respostasSelecionadas);
        console.log('🚀 ~ payloadForBudget ~ payloadForBudget:', payloadForBudget);

        // Substituir placeholders na nota
        const answersMap = createAnswersMap(payloadForBudget);
        const updatedNote = replacePlaceholders(note, answersMap);

        // Manipulando storage
        const newArrayWithNames = payloadForBudget.map((obj) => {
            // Criar uma cópia do objeto atual
            const newObj = { ...obj };
            // Adicionar o campo "nome" ao objeto
            newObj.nome = nome; // Substitua 'Nome Exemplo' pelo valor desejado
            // Retornar o novo objeto
            return newObj;
        });
        const newQuestions = JSON.stringify(newArrayWithNames);
        localStorage.setItem('questions', newQuestions);

        const respostaAtual = respostasSelecionadas[answers[perguntaAtual].question.id];
        if (respostaAtual === undefined) {
            setMostrarErro(true);
            setLoading(false);
            return;
        }

        if (
            openResponse < answers[perguntaAtual]?.openQuestionRestriction?.min ||
            openResponse > answers[perguntaAtual]?.openQuestionRestriction?.max
        ) {
            toast.warn(
                `Selecione um valor entre ${answers[perguntaAtual]?.openQuestionRestriction?.min} e ${answers[perguntaAtual]?.openQuestionRestriction?.max}`
            );
            setLoading(false);
            return;
        }

        // Verificar se todas as perguntas foram respondidas
        const todasRespondidas = payloadForBudget.every((resposta) => resposta.answer !== undefined);

        try {
            // Primeira requisição: criar orçamentos
            const response = await api.createBudgets(
                currentQuizMain.id,
                payloadForBudget.map((resposta) => ({
                    question: resposta.question,
                    answer: {
                        ...resposta.answer,
                        value: resposta.answer.description !== '' ? parseFloat(resposta.answer.value) : null
                    }
                }))
            );

            setPreOrc(response.data.materials);
            setSaveModel(response.data);
            // handleNote(response.data.id, updatedNote);

            const rescalc = await api.calcRo(currentQuizMain.id, id, {
                answerQuizModels: payloadForBudget.map((resposta) => ({
                    question: resposta.question,
                    answer: {
                        ...resposta.answer,
                        value: resposta.answer.value !== '' ? parseFloat(resposta.answer.value) : null
                    }
                })),
                budgetsModel: response.data
            });

            let lastBudget = rescalc.data.budget[rescalc.data.budget.length - 1];

            handleNote(lastBudget.id, updatedNote);

            handleClose();
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            if (error?.response?.status === 400) {
                setOpen(false);
                return toast.error(error?.response?.data?.message);
            }
            if (error?.response?.status !== 400) {
                setOpen(false);
                return toast.error('Um erro inesperado aconteceu, verifique o r.o');
            }
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
            <>
                <Grid
                    item
                    sm={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        border: '1px solid white',
                        borderRadius: '30px',
                        maxHeight: '400px',
                        padding: '20px',
                        backgroundColor: 'white',
                        flexDirection: 'row-reverse',
                        gap: '40px'
                    }}
                >
                    {!loading && (
                        <CardContent>
                            <img
                                src={answers[stepImage]?.imageUrl ? answers[stepImage]?.imageUrl : LogoJGF}
                                alt="Imagem 1"
                                style={{
                                    minWidth: '300px',
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                    margin: '10px',
                                    border: '1px solid white',
                                    borderRadius: '30px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'
                                }}
                            />
                        </CardContent>
                    )}

                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'center' }}>
                        {loading ? (
                            <SkeletonSimulator />
                        ) : (
                            <>
                                {nome === '' && (
                                    <>
                                        <FormControl required sx={{ minWidth: '400px', justifyContent: 'center', marginTop: '30px' }}>
                                            <InputLabel>Selecione um grupo</InputLabel>
                                            <Select
                                                value={selectedGroupValue?.id || ''}
                                                onChange={(event) => {
                                                    const newValue = allGroup.find((item) => item.id === event.target.value);
                                                    setSelectedGroupValue(newValue);
                                                    if (newValue) {
                                                        getGroupRelated(newValue.id);
                                                    }
                                                }}
                                                label="Selecione um grupo"
                                            >
                                                {allGroup
                                                    .filter((item) => item?.finished === true)
                                                    .map((group) => (
                                                        <MenuItem key={group.id} value={group.id}>
                                                            {group.groupDescription}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>

                                        {groupIsSelected && (
                                            <FormControl sx={{ width: '100%', marginBottom: '15px' }} required>
                                                <InputLabel>Seleção de questionário</InputLabel>
                                                <Select
                                                    value={selectedQuizValue?.id || ''} // Usando o ID do questionário selecionado
                                                    onChange={(event) => {
                                                        const newValue = allQuiz.find((quiz) => quiz.id === event.target.value);
                                                        handleQuizChange(newValue); // Mantendo a lógica original
                                                        setSelectedQuizValue(newValue);
                                                    }}
                                                    label="Seleção de questionário"
                                                    sx={{ minWidth: '400px', justifyContent: 'center' }}
                                                >
                                                    {allQuiz
                                                        .filter((item) => item.enabled === true)
                                                        .map((quiz) => (
                                                            <MenuItem key={quiz.id} value={quiz.id}>
                                                                {quiz.product?.productName}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </>
                                )}
                                {nome !== '' && (
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                            color: '#2F80ED',
                                            borderRadius: '10px',
                                            padding: '10px',
                                            marginTop: '10px'
                                        }}
                                    >
                                        {nome}
                                    </Typography>
                                )}

                                {answers && answers[perguntaAtual] && (
                                    <FormControl sx={{ width: '400px', mb: '15px' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography variant="subtitle1">
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '8px',
                                                        width: 'auto'
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            marginTop: '10px',
                                                            fontWeight: 'bold',
                                                            color: '#333333',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {answers[perguntaAtual]?.question?.description}
                                                    </span>
                                                    <span style={{ textAlign: 'center', color: '#FF6666' }}>
                                                        {answers[perguntaAtual]?.openQuestionRestriction?.description || ''}
                                                    </span>
                                                </div>
                                            </Typography>
                                            {answers[perguntaAtual].question.type === 'Aberta' ? (
                                                <TextField
                                                    type="number"
                                                    placeholder="Digite sua resposta"
                                                    error={mostrarErro}
                                                    value={respostasSelecionadas[answers[perguntaAtual].question.id]?.answer?.value || ''}
                                                    helperText={mostrarErro ? 'Campo obrigatório' : ''}
                                                    onChange={(e) => {
                                                        setMostrarErro(false);
                                                        handleRespostaSelecionada(
                                                            answers[perguntaAtual].question,
                                                            e.target.value !== '' ? e.target.value : undefined,
                                                            answers[perguntaAtual]
                                                        );
                                                    }}
                                                    sx={{ marginTop: '8px', width: '300px' }}
                                                />
                                            ) : (
                                                <>
                                                    <Select
                                                        sx={{ minWidth: '300px', justifyContent: 'center' }}
                                                        value={respostasSelecionadas[answers[perguntaAtual].question.id]?.answer?.id || ''} // Usando o ID da resposta selecionada
                                                        onChange={(event) => {
                                                            setMostrarErro(false);
                                                            const newValue = answers[perguntaAtual].answers.find(
                                                                (item) => item.id === event.target.value
                                                            );
                                                            handleRespostaSelecionada(
                                                                answers[perguntaAtual].question,
                                                                newValue ? newValue : undefined
                                                            );
                                                        }}
                                                    >
                                                        {answers[perguntaAtual].answers.map((item) => (
                                                            <MenuItem key={item.id} value={item.id}>
                                                                {item.public_description ? item.public_description : item.publicDescription}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: '70px',
                                                padding: '0 30px'
                                            }}
                                        >
                                            {perguntaAtual > 0 && (
                                                <Button type="button" variant="contained" onClick={handleVoltar}>
                                                    Voltar
                                                </Button>
                                            )}

                                            {perguntaAtual === answers.length - 1 ? (
                                                <Button type="button" variant="contained" onClick={handleSubmit}>
                                                    Enviar
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    disabled={isButtonDisabled}
                                                    onClick={handleAvancar}
                                                >
                                                    Avançar
                                                </Button>
                                            )}
                                        </Box>
                                    </FormControl>
                                )}
                            </>
                        )}
                    </form>
                </Grid>
            </>
        </Dialog>
    );
};

export default BudgetFormation;
